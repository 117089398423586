import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./home/Home.js";
import Servico from "./pages/Servico.js";
import Pet from "./pages/Pet.js";
import NavBar from "./structure/NavBar.js";
import ServicoAdicionar from "./pages/ServicoAdicionar.js";
import Eventos from "./pages/eventos/Eventos";

function App(){
    return (
        <BrowserRouter>
            <NavBar/>

            <Routes>
                <Route path="/">
                    <Route index element={<Home />} />

                    <Route path="eventos" element={<Eventos />} />

                    <Route path="servico">
                        <Route index element={<Servico/>} />
                        <Route path="adicionar" element={<ServicoAdicionar/>} />
                    </Route>
                    
                    <Route path="pet" element={<Pet />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App;
