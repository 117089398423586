import {Container} from "react-bootstrap";

function Eventos(){
    return(
        <Container>
            <h1>
                Pagina Eventos
            </h1>
        </Container>
    )
}

export default Eventos;
