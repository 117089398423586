import { Button, Container, Form } from "react-bootstrap";

function ServicoAdicionar(){
    return(
        <Container>
            <Form>
                <Form.Group className="mb-3" controlId="formNome">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type="text" placeholder="Nome"/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formValor">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control type="text" placeholder="Valor" />
                </Form.Group>



                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </Container>
    )
}


export default ServicoAdicionar;