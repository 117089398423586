import { Button, Container, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

function Servico(){
    
    const servicos = [
        {
            id: 1,
            nome: 'tosa',
            valor: 30,
        },
        {
            id: 2,
            nome: 'passeio',
            valor: 10,
        },
    ];
    
    return(
        <Container>
            <br/>
            <Button as={Link} to="/servico/adicionar">+</Button>
            <br/><br/>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Id.</th>
                        <th>Nome</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {servicos.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.nome}</td>
                            <td>{item.valor}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}

export default Servico;