import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

function NavBar(){
    return(
        <>
            <Navbar sticky="top" expand="lg"  bg="dark" data-bs-theme="dark" >
                <Container>
                    <Navbar.Brand as={Link} to="/">Brasil Eventos</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/eventos">Eventos</Nav.Link>
                            <Nav.Link as={Link} to="/servico">Sou Participante</Nav.Link>
                            <Nav.Link as={Link} to="/pet">Gestor de Eventos </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavBar;
