import {Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import styles from "./Hero.css";

function Hero(){
    return (
        <section id="hero" className="d-flex align-items-center">
            <Container>
                <Row>
                    <Col className="col-lg-6 col-sm-12 col-12 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
                         data-aos="fade-up" data-aos-delay="200">
                        <h1>Organizando um evento?</h1>
                        <h2>Divulgue, publique as inscrições e se inscreva em eventos aqui.</h2>
                        <div className="divHeroButton">
                            <Button as={Link} to="#about" className="btn-get-started scrollto">Criar Evento</Button>
                            <Button as={Link} to="#about" className="btn-get-started scrollto">Buscar Eventos</Button>
                        </div>
                    </Col>
                    <Col className="col-lg-6 col-sm-12 col-12 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                        <img src="assets/img/hero-img.png" className="img-fluid animated" alt=""/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Hero;
